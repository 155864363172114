<template>
  <div>
    <div class="add-conditions__row">
      <label for="add-conditions__service-fee-matrix" class="add-conditions__label">
        {{ $t('creditors.serviceFees') }}:
      </label>
      <service-fee-matrix-radio-group
        id="add-conditions__service-fee-matrix"
        :creditor-product-type="details.product"
        :value="serviceFeeMatrixId"
        name="serviceFees.conditions"
        rules="required"
        @input="$emit('update:service-fee-matrix-id', $event)"
      />
    </div>
    <div class="add-conditions__row add-conditions__date">
      <label for="add-conditions__date" class="add-conditions__label">
        {{ $t('creditors.manageConditions.validOn') }}:
      </label>
      <div>
        <app-date-picker
          id="add-conditions__date"
          :min="minStart"
          :value="startDate"
          append-icon
          name="date"
          placeholder="dateHint"
          :rules="startDateRules"
          :error-message="startDateErrorMessage"
          @input="$emit('update:start-date', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import formatDate, { formatIsoToLocal, formatTimestampToDate } from '@/helper/filter/formatDate';
import ServiceFeeMatrixRadioGroup from '@/modules/Admin/components/Creditors/ServiceFeeMatrixRadioGroup';
import { MILLISECONDS_PER_DAY } from '@/statics/timePeriods';

export default {
  name: 'AddConditions',

  components: { ServiceFeeMatrixRadioGroup },

  props: {
    conditions: {
      type: Array,
      required: true
    },
    details: {
      type: Object,
      required: true
    },
    serviceFeeMatrixId: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required: true
    }
  },

  computed: {
    minStart() {
      return formatTimestampToDate((new Date().setHours(0, 0, 0) + MILLISECONDS_PER_DAY) / 1000);
    },
    conditionsStartDates() {
      return this.conditions.map((condition) => formatDate(condition.startDate));
    },
    startDateErrorMessage() {
      const conditionsWithSameStartDateExistent = this.conditionsStartDates.includes(
        formatIsoToLocal(this.startDate)
      );

      if (conditionsWithSameStartDateExistent) {
        return this.$t('creditors.manageConditions.errorStartDateAlreadyTaken');
      }

      return '';
    },
    startDateRules() {
      return 'required|excluded:' + this.conditionsStartDates.join(',');
    }
  }
};
</script>

<style scoped lang="scss">
.add-conditions__row {
  margin-top: 32px;
}

.add-conditions__label {
  margin-bottom: 4px;
  display: inline-block;
}

.add-conditions__date {
  display: flex;
  align-items: baseline;
  max-width: calc(50% - 8px);

  > .add-conditions__label {
    margin-right: 40px;
    min-width: max-content;
  }

  > .app-date-picker__wrapper {
    flex: 1;
  }
}
</style>
