<template>
  <app-select
    filter
    :items="states"
    :label="$t('status')"
    multiple
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { CREDITOR_STATES } from '@/modules/Admin/statics/creditorStatus';
import formatCreditorStatus from '@/helper/filter/formatCreditorStatus';

export default {
  name: 'StatusSelect',

  computed: {
    states() {
      return CREDITOR_STATES.map((status) => ({
        label: formatCreditorStatus(status),
        value: status
      }));
    }
  }
};
</script>
