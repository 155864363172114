<template>
  <div>
    <app-dialog
      v-model="open"
      title="creditors.triggerSapRegistration"
      primary-action-text="navigation.yesSend"
      secondary-action-text="navigation.noCancel"
      :loading="loading"
      @submit="onSubmit"
      @close="close"
    >
      <app-alert v-if="error" :value="!!error">
        {{ $t('errorOccured') }}:
        <p class="mt-4">{{ error.statusText }}</p>
        <p class="mt-4">{{ error.content }}</p>
      </app-alert>
      <p>{{ $t('creditors.requestSapRegistration', { name: details.creditorName }) }}</p>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="creditors.creditorSentToSap"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';
import CreditorDialogMixin from '@/mixins/CreditorDialogMixin';

export default {
  name: 'RegisterSapDialog',

  mixins: [CreditorDialogMixin],

  methods: {
    ...mapActions(NAMESPACE, ['registerCreditorToSap']),

    async onSubmit() {
      this.loading = true;

      const { error } = await this.registerCreditorToSap(this.creditorId);

      this.loading = false;
      if (error) {
        this.error = error.response?.data;
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
