<template>
  <div>
    <app-select
      filter
      :label="$t('creditors.registrationState')"
      content-class="main-select__menu"
      hide-details
      dense
      solo
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #prepend-item>
        <v-container>
          <v-row dense>
            <v-col>
              <app-select
                v-model="sapRegisteredSelection"
                :items="selectOptions"
                :label="$t('creditors.registrationStates.sapRegistered')"
                filter
                hide-details
                dense
                solo
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <app-select
                v-model="userCreatedSelection"
                :items="selectOptions"
                :label="$t('creditors.registrationStates.userCreated')"
                filter
                hide-details
                dense
                solo
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <app-btn min-height="30" class="subtitle-1" block @click="filterItems">
                {{ $t('filter') }}
              </app-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </app-select>
  </div>
</template>

<script>
export default {
  name: 'RegistrationStatusFilter',

  props: {
    sapRegistered: {
      type: Array,
      default: () => []
    },
    setUpFeeCharged: {
      type: Array,
      default: () => []
    },
    userCreated: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    sapRegisteredSelection: undefined,
    setUpFeeChargedSelection: undefined,
    userCreatedSelection: undefined,
    showMenu: false
  }),

  computed: {
    selectOptions() {
      return [
        {
          label: this.$t('yes'),
          value: 'yes'
        },
        {
          label: this.$t('no'),
          value: 'no'
        }
      ];
    }
  },

  watch: {
    sapRegistered() {
      this.sapRegisteredSelection = this.sapRegistered;
    },
    setUpFeeCharged() {
      this.setUpFeeChargedSelection = this.setUpFeeCharged;
    },
    userCreated() {
      this.userCreatedSelection = this.userCreated;
    }
  },

  methods: {
    filterItems() {
      if (this.sapRegisteredSelection && this.sapRegisteredSelection !== this.sapRegistered) {
        this.$emit(`update:sapRegistered`, this.sapRegisteredSelection);
      }
      if (this.setUpFeeChargedSelection && this.setUpFeeChargedSelection !== this.setUpFeeCharged) {
        this.$emit(`update:setUpFeeCharged`, this.setUpFeeChargedSelection);
      }
      if (this.userCreatedSelection && this.userCreatedSelection !== this.userCreated) {
        this.$emit(`update:userCreated`, this.userCreatedSelection);
      }

      this.showMenu = false;
    }
  },

  created() {
    this.sapRegisteredSelection = this.sapRegistered;
    this.setUpFeeChargedSelection = this.setUpFeeCharged;
    this.userCreatedSelection = this.userCreated;
  }
};
</script>

<style>
.main-select__menu {
  width: 0;
}

.main-select__menu .v-list-item {
  display: none;
}
</style>
