<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form" @submit.prevent="onSubmit">
    <app-dialog
      v-model="open"
      title="creditors.sendRegistrationCaseFile.title"
      primary-action-text="navigation.send"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      :disabled="failed"
      @submit="onSubmit"
      @close="close"
    >
      <v-row>
        <v-col cols="12">
          <app-text-field
            v-model="formData.apiIdentifier"
            label="creditors.sendRegistrationCaseFile.apiIdentifier"
            required
          />
        </v-col>
        <v-col cols="12">
          <app-text-field
            v-model="formData.statusCode"
            label="creditors.sendRegistrationCaseFile.statusCode"
            rules="required|numeric|length:3"
          />
        </v-col>
        <v-col cols="12">
          <app-text-field
            v-model="formData.statusText"
            label="creditors.sendRegistrationCaseFile.statusText"
            required
          />
        </v-col>
      </v-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="creditors.sendRegistrationCaseFile.submitted"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
import CreditorDialogMixin from '@/mixins/CreditorDialogMixin';
import showSnackbar from '@/helper/showSnackbar';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapActions } from 'vuex';

export default {
  name: 'SendRegistrationCaseFileDialog',

  mixins: [CreditorDialogMixin],

  data: () => ({
    formData: {
      statusCode: '',
      statusText: '',
      apiIdentifier: ''
    }
  }),

  methods: {
    ...mapActions(NAMESPACE, ['sendRegistrationCaseFile']),

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;

      const { error } = await this.sendRegistrationCaseFile({
        creditorSapNumber: this.details.sapNumber,
        payload: {
          apiIdentifier: this.formData.apiIdentifier,
          status: parseInt(this.formData.statusCode),
          statusText: this.formData.statusText
        }
      });

      this.loading = false;
      if (error) {
        showSnackbar({ text: 'serverError', color: 'error' });
        return;
      }

      this.submitted = true;
      setTimeout(() => this.resetForm(), 200);
    },

    resetForm() {
      this.formData = {
        statusCode: '',
        statusText: '',
        apiIdentifier: ''
      };
      this.$refs.form?.reset();
    }
  },

  watch: {
    details: {
      immediate: true,
      handler() {
        this.resetForm();
      }
    }
  }
};
</script>
