<template>
  <div>
    <app-dialog
      v-model="open"
      title="creditors.editCreditor"
      :loading="loading"
      width="80vw"
      @submit="onSubmit"
      @close="onCancel"
    >
      <register-creditor-stepper
        v-if="selectedProductType"
        :form-data.sync="formData"
        :loading="loading"
        :non-linear="true"
        :selected-product-type="selectedProductType"
        :steps="steps"
        @cancel="onCancel"
        @submit="onSubmit"
      />
      <app-spinner v-else />
    </app-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';
import { createCreditorFactory, mapPayload, mapDetails } from '@/helper/createCreditorFactory';
import CreditorDialogMixin from '@/mixins/CreditorDialogMixin';
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';
import RegisterCreditorStepper from '@/shared/CreateCreditor/RegisterCreditorStepper';

export default {
  name: 'EditCreditorDialog',

  components: {
    RegisterCreditorStepper
  },

  mixins: [CreditorDialogMixin, OnboardingProductMixin],

  data: () => ({
    formData: createCreditorFactory()
  }),

  computed: {
    steps() {
      return [
        {
          step: 1,
          title: 'company',
          component: 'company-form'
        },
        {
          step: 2,
          title: 'onboarding.legalFormalities',
          component: 'legal-formalities-form'
        },
        {
          step: 3,
          title: 'onboarding.sepa',
          component: 'sepa-form'
        },
        {
          step: 4,
          title: 'onboarding.intermediary',
          component: 'intermediary-form'
        },
        {
          step: 5,
          title: 'creditors.contract',
          component: 'contract-form'
        },
        {
          step: 6,
          title: 'creditors.conditions',
          component: 'conditions-form'
        }
      ];
    },

    selectedProductType: {
      get() {
        return this.getQueryParam(this.queryParam.SELECTED_PRODUCT_TYPE);
      },
      set(value) {
        this.setQueryParam(this.queryParam.SELECTED_PRODUCT_TYPE, value);
      }
    }
  },

  watch: {
    creditorId: {
      immediate: true,
      async handler(creditorId) {
        if (!creditorId) return;

        const { content, error } = await this.fetchDetails(creditorId);

        if (error) return;

        this.formData = mapDetails(content, creditorId);
        this.selectedProductType = content.productType;
      }
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['updateCreditor', 'fetchDetails']),

    async onSubmit() {
      this.loading = true;

      this.formData = this.fillBlacklistedDataWithDefaults(this.formData);

      const payload = {
        ...mapPayload({
          product: this.selectedProductType,
          formData: this.formData,
          withCreditorLimit: this.dataNotBlacklisted(this.fields.CREDITOR_LIMIT),
          maxCreditorLimitInCent: this.selectedProduct.onboarding.maxCreditorLimitInCent,
          useCalculatedCreditorLimit: this.selectedProduct.onboarding.useCalculatedCreditorLimit
        }),
        id: this.creditorId
      };

      const { error } = await this.updateCreditor(payload);
      this.loading = false;
      if (error) {
        return;
      }

      this.$emit('success');

      this.closeAfterSubmit();
    },

    onCancel() {
      this.selectedProductType = '';
      this.resetForm();
      this.error = false;
      this.$emit('input', undefined);
    },

    closeAfterSubmit() {
      this.submitted = false;
      this.selectedProductType = '';
      this.error = false;
      this.resetForm();
      this.$emit('input', undefined);
    },

    resetForm() {
      setTimeout(() => {
        if (this.getQueryParam(this.queryParam.STEP)) {
          this.setQueryParam([this.queryParam.STEP], undefined, true);
        }
        this.formData = createCreditorFactory();
      }, 200);
    }
  }
};
</script>

<style scoped>
.v-progress-circular {
  margin: 10rem auto;
}
</style>
