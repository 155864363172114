<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form" @submit.prevent="onSubmit">
    <app-dialog
      v-model="open"
      title="creditors.requestSepaUpdate.title"
      primary-action-text="navigation.send"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      :disabled="failed"
      @submit="onSubmit"
      @close="close"
    >
      <p>{{ $t('creditors.requestSepaUpdate.info') }}</p>
      <app-input-row label="creditor">
        <disabled-input-box :value="details.creditorName" />
      </app-input-row>
      <app-input-row label="onboarding.accountOwner">
        <app-textarea
          v-model="formData.accountOwner"
          label="onboarding.accountOwner"
          rows="3"
          required
        />
      </app-input-row>
      <app-input-row label="iban">
        <app-text-field
          v-model="formData.iban"
          name="iban"
          placeholder="DEXX XX XXXXXXXX"
          rules="required|iban"
        />
      </app-input-row>
      <app-input-row label="date">
        <app-date-picker v-model="formData.start" placeholder="dateHint" name="date" required />
      </app-input-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="creditors.requestSepaUpdate.title"
      text="creditors.requestSepaUpdate.submitted"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';
import { formatDateToTimestamp } from '@/helper/filter/formatDate';
import CreditorDialogMixin from '@/mixins/CreditorDialogMixin';
import DisabledInputBox from '@/shared/components/DisabledInputBox';

export default {
  name: 'RequestSepaUpdateDialog',

  mixins: [CreditorDialogMixin],

  components: { DisabledInputBox },

  data: () => ({
    formData: {
      accountOwner: '',
      iban: '',
      start: ''
    }
  }),

  watch: {
    creditorId() {
      this.resetForm();
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['requestSepaUpdate']),

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;

      const { error } = await this.requestSepaUpdate({
        creditorIdentifier: this.creditorId,
        iban: this.formData.iban,
        accountOwner: this.formData.accountOwner,
        startSepaDate: formatDateToTimestamp(this.formData.start)
      });

      this.loading = false;
      if (error) {
        return;
      }

      this.submitted = true;
    },

    resetForm() {
      this.$refs.form?.reset();
      this.formData = {
        accountOwner: '',
        iban: '',
        start: ''
      };
    }
  }
};
</script>
