<template>
  <div>
    <app-dialog
      v-if="hasCreditorCreationPermission"
      v-model="open"
      title="creditors.createCreditor"
      :loading="loading"
      width="80vw"
      @submit="onSubmit"
      @close="onCancel"
    >
      <div v-if="!selectedProductType && !isProductAdmin" class="mt-4 mb-12">
        <v-row justify="center">
          <v-col v-for="(item, index) in productGroup" :key="index" cols="auto">
            <v-card class="pa-2 text-center" width="380px" @click="selectedProductType = item.type">
              <v-card-title
                class="
                  gradient-diagonal--primary
                  background-text--text
                  text-h3
                  semi-bold
                  justify-center
                "
              >
                {{ item.productName }}
              </v-card-title>
              <v-card-text class="pt-6 px-6">
                <v-row class="partner-logos__row">
                  <v-col class="partner-logos__col" cols="auto">
                    <img :src="item.logo" height="45px" />
                  </v-col>
                  <vertical-divider v-if="item.partnerLogo" />
                  <v-col class="partner-logos__col" cols="auto">
                    <img :src="item.partnerLogo" height="45px" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <register-creditor-stepper
        v-if="selectedProductType"
        :form-data.sync="formData"
        :selected-product-type="selectedProductType"
        :steps="steps"
        :loading="loading"
        @cancel="onCancel"
        @submit="onSubmit"
      >
        <template #prepend-inner>
          <app-alert v-if="!!error" class="mb-4">
            {{ $t(`creditors.${error}`) }}
          </app-alert>
        </template>
      </register-creditor-stepper>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="creditors.createNextCreditor"
      secondary-action-text="navigation.backToOverview"
      title="creditors.createdCreditor"
      @submit="createNextCreditor"
      @close="closeAfterSubmit"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';
import {
  ERROR_CODE_DDM_OR_CSP_ALREADY_ASSIGNED,
  ERROR_CODE_CREDITOR_EMAIL_ALREADY_ASSIGNED
} from '@/statics/errorCodes';
import { createCreditorFactory, mapPayload } from '@/helper/createCreditorFactory';
import showSnackbar from '@/helper/showSnackbar';
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import RegisterCreditorStepper from '@/shared/CreateCreditor/RegisterCreditorStepper';
import VerticalDivider from '@/shared/CreateCreditor/VerticalDivider';

export default {
  name: 'CreateCreditorDialog',

  mixins: [ShowDialogMixin, OnboardingProductMixin],

  components: {
    RegisterCreditorStepper,
    VerticalDivider
  },

  data: () => ({
    formData: createCreditorFactory(),
    submitted: false,
    error: ''
  }),

  computed: {
    steps() {
      return [
        {
          step: 1,
          title: 'company',
          component: 'company-form'
        },
        {
          step: 2,
          title: 'onboarding.legalFormalities',
          component: 'legal-formalities-form'
        },
        {
          step: 3,
          title: 'onboarding.sepa',
          component: 'sepa-form'
        },
        {
          step: 4,
          title: 'onboarding.intermediary',
          component: 'intermediary-form'
        },
        {
          step: 5,
          title: 'creditors.contract',
          component: 'contract-form'
        },
        {
          step: 6,
          title: 'creditors.conditions',
          component: 'conditions-form'
        }
      ];
    },
    selectedProductType: {
      get() {
        return this.getQueryParam(this.queryParam.SELECTED_PRODUCT_TYPE);
      },
      set(value) {
        if (!this.getQueryParam(this.queryParam.SELECTED_PRODUCT_TYPE) && !value) {
          return;
        }

        this.setQueryParam(this.queryParam.SELECTED_PRODUCT_TYPE, value);
      }
    }
  },

  watch: {
    selectedProductType: {
      immediate: true,
      handler() {
        if (!this.productGroup.some((product) => product.type === this.selectedProductType)) {
          this.selectedProductType = '';
        }
      }
    },

    '$route.query.step'() {
      if (this.error) {
        this.error = '';
      }
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['createCreditor']),

    async onSubmit() {
      this.loading = true;

      this.formData = this.fillBlacklistedDataWithDefaults(this.formData);

      const payload = {
        ...mapPayload({
          product: this.selectedProductType,
          formData: this.formData,
          withCreditorLimit: this.dataNotBlacklisted(this.fields.CREDITOR_LIMIT),
          maxCreditorLimitInCent: this.selectedProduct.onboarding.maxCreditorLimitInCent,
          useCalculatedCreditorLimit: this.selectedProduct.onboarding.useCalculatedCreditorLimit
        })
      };

      const { error } = await this.createCreditor(payload);
      this.loading = false;
      if (error) {
        if (error.response?.data.error === ERROR_CODE_CREDITOR_EMAIL_ALREADY_ASSIGNED) {
          this.error = 'creditorEmailAlreadyAssigned';
          return;
        }

        if (error.response?.data.error === ERROR_CODE_DDM_OR_CSP_ALREADY_ASSIGNED) {
          this.error = 'ddmOrCspAlreadyAssigned';
          return;
        }

        showSnackbar({ text: 'serverError', color: 'error' });
        return;
      }

      this.$emit('success');

      this.submitted = true;
      this.selectedProductType = '';
      this.resetForm();
    },

    onCancel() {
      setTimeout(() => {
        this.selectedProductType = '';
        this.resetForm();
      }, 200);

      if (this.isProductAdmin) {
        this.$emit('input', false);
      }
    },

    createNextCreditor() {
      this.submitted = false;
    },

    closeAfterSubmit() {
      this.submitted = false;
      this.$emit('input', false);
    },

    resetForm() {
      if (this.getQueryParam(this.queryParam.STEP)) {
        this.setQueryParam([this.queryParam.STEP], undefined, true);
      }
      this.formData = createCreditorFactory();
    }
  },

  created() {
    if (this.$auth.user()?.product) {
      this.selectedProductType = this.$auth.user().product;
    }
  }
};
</script>

<style scoped lang="scss">
.partner-logos__row {
  margin-top: 16px;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  .partner-logos__col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: auto;
    flex-shrink: 1;
    flex-grow: 0;
    max-width: 50%;
  }

  img {
    display: block;
    max-width: 100%;
    object-fit: contain;
  }
}

::v-deep .v-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}
::v-deep .v-card__title {
  word-break: break-word;
  flex: 1;
}
</style>
