<template>
  <div>
    <app-dialog
      v-model="open"
      title="creditors.triggerSetUpFee"
      primary-action-text="navigation.yesSend"
      secondary-action-text="navigation.noCancel"
      :loading="loading"
      :disabled="details.setUpFeeCharged && !confirmRenewedFee"
      @submit="onSubmit"
      @close="close"
    >
      <app-alert v-if="error" :value="!!error">
        {{ $t('errorOccured') }}:
        <p class="mt-4">{{ error.statusText }}</p>
        <p class="mt-4">{{ error.content }}</p>
      </app-alert>
      <p>{{ $t('creditors.requestSetUpFee', { name: details.creditorName, setUpFee }) }}</p>

      <template v-if="details.setUpFeeCharged">
        <app-alert color="info" :icon="false" class="my-4 subtitle-1">
          <div class="semi-bold">{{ $t('pleaseNote') }}:</div>
          {{ $t('creditors.setUpFeeAlreadyCharged') }}
        </app-alert>

        <app-checkbox
          v-model="confirmRenewedFee"
          label="creditors.confirmRenewedFee"
          hide-details
        />
      </template>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="creditors.setUpFeeCharged"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    >
      {{ response }}
    </app-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';
import CreditorDialogMixin from '@/mixins/CreditorDialogMixin';
import formatCurrency from '@/helper/filter/formatCurrency';

export default {
  name: 'ChargeSetUpFeeDialog',

  mixins: [CreditorDialogMixin],

  data: () => ({
    response: '',
    confirmRenewedFee: false
  }),

  computed: {
    setUpFee() {
      if (!this.details) {
        return formatCurrency(0);
      }

      return formatCurrency(this.details.setUpFee);
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['chargeSetUpFee']),

    async onSubmit() {
      this.loading = true;

      const { response, error } = await this.chargeSetUpFee(this.creditorId);

      this.loading = false;
      if (error) {
        this.error = error.response?.data;
        return;
      }

      this.response = response;
      this.submitted = true;
    }
  }
};
</script>
