<template>
  <v-container fluid pa-0>
    <add-btn
      v-if="hasCreditorCreationPermission"
      @click="setQueryParam(queryParam.CREATE_CREDITOR, true)"
    />

    <creditors-filter />
    <creditors-table :table-data="creditors" :count="creditorsCount" :creditor-actions="actions" />

    <component
      :is="selectedAction.component"
      v-if="selectedAction && !creditorNotFound"
      v-model="showActionDialog"
      :creditor-id="getQueryParam(selectedAction.param)"
      :details="detailedCreditor"
      @success="loadCreditors"
    />
  </v-container>
</template>

<script>
import AddBtn from '@/shared/components/AddBtn';
import ChargeSetUpFeeDialog from '@/modules/Admin/components/Creditors/Actions/ChargeSetUpFeeDialog';
import CreateCreditorDialog from '@/modules/Admin/components/Creditors/Actions/CreateCreditorDialog';
import CreateUserDialog from '@/modules/Admin/components/Creditors/Actions/CreateUserDialog';
import CreditorListMixin from '@/modules/Admin/mixins/CreditorListMixin';
import CreditorsFilter from '@/modules/Admin/components/Creditors/CreditorsFilter';
import CreditorsTable from '@/modules/Admin/components/Creditors/CreditorsTable';
import DisableDialog from '@/modules/Admin/components/Creditors/Actions/DisableDialog';
import EditCreditorDialog from '@/modules/Admin/components/Creditors/Actions/EditCreditorDialog';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import ManageConditionsDialog from '@/modules/Admin/components/Creditors/Actions/ManageConditionsDialog/ManageConditionsDialog';
import PasswordResetDialog from '@/modules/Admin/components/Creditors/Actions/PasswordResetDialog';
import ProductType from '@/statics/productType';
import RegisterSapDialog from '@/modules/Admin/components/Creditors/Actions/RegisterSapDialog';
import RequestSepaUpdateDialog from '@/modules/Admin/components/Creditors/Actions/RequestSepaUpdateDialog';
import SendRegistrationCaseFileDialog from '@/modules/Admin/components/Creditors/Actions/SendRegistrationCaseFileDialog';
import SendRegistrationMailDialog from '@/modules/Admin/components/Creditors/Actions/SendRegistrationMailDialog';
import SetCreditorPermissionsDialog from '@/modules/Admin/components/Creditors/Actions/SetCreditorPermissionsDialog';
import SetDriverPermissionsDialog from '@/modules/Admin/components/Creditors/Actions/SetDriverPermissionsDialog';
import TerminateCreditorDialog from '@/modules/Admin/components/Creditors/Actions/TerminateCreditorDialog';
import UpdateUserDialog from '@/modules/Admin/components/Creditors/Actions/UpdateUserDialog';
import { NAMESPACE } from '@/modules/Admin/store';
import { QueryParam } from '@/statics/queryParam';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'Creditors',

  mixins: [CreditorListMixin, ListFilterMixin],

  components: {
    AddBtn,
    ChargeSetUpFeeDialog,
    CreateCreditorDialog,
    CreateUserDialog,
    CreditorsFilter,
    CreditorsTable,
    DisableDialog,
    EditCreditorDialog,
    PasswordResetDialog,
    RegisterSapDialog,
    RequestSepaUpdateDialog,
    SendRegistrationCaseFileDialog,
    SendRegistrationMailDialog,
    SetCreditorPermissionsDialog,
    SetDriverPermissionsDialog,
    UpdateUserDialog
  },

  data: () => ({
    queryValues: [
      QueryParam.PAGE,
      QueryParam.ITEMS_PER_PAGE,
      QueryParam.SORT_BY,
      QueryParam.SORT_DESC,
      'city',
      'creditorName',
      'product',
      'sapNumber',
      'sapRegistered',
      'status',
      'userCreated',
      'userName'
    ],
    defaultSorting: {
      sortBy: ['sapNumber'],
      sortDesc: [false]
    }
  }),

  computed: {
    ...mapState(NAMESPACE, ['creditors', 'creditorsCount']),
    ...mapGetters(NAMESPACE, { getCreditorById: 'GET_CREDITOR_BY_ID' }),

    detailedCreditor() {
      return this.getCreditorById(this.getQueryParam(this.selectedAction?.param));
    },

    actions() {
      return [
        {
          param: this.queryParam.CREATE_CREDITOR,
          component: CreateCreditorDialog,
          permission: () => this.hasCreditorCreationPermission,
          callback: (creditor) => {
            this.setQueryParam(this.queryParam.CREATE_CREDITOR, creditor.id);
          }
        },
        {
          param: this.queryParam.EDIT_CREDITOR,
          title: () => 'creditors.editCreditor',
          component: EditCreditorDialog,
          permission: () => this.hasCreditorCreationPermission,
          callback: (creditor) => {
            this.setQueryParam(this.queryParam.EDIT_CREDITOR, creditor.id);
          }
        },
        {
          param: this.queryParam.SAP_REGISTRATION,
          component: RegisterSapDialog,
          title: (creditor) =>
            creditor.isSentToSap
              ? 'creditors.triggerSapRegistrationAgain'
              : 'creditors.triggerSapRegistration',
          permission: () => this.hasSapRegistrationPermission,
          callback: (creditor) => {
            this.setQueryParam(this.queryParam.SAP_REGISTRATION, creditor.id);
          }
        },
        {
          param: this.queryParam.SEND_REGISTRATION_CASE_FILE,
          component: SendRegistrationCaseFileDialog,
          title: () => 'creditors.sendRegistrationCaseFile.title',
          permission: () => this.isSuperAdmin,
          condition: (creditor) => creditor?.product === ProductType.TPF_FACTORING,
          callback: (creditor) => {
            this.setQueryParam(this.queryParam.SEND_REGISTRATION_CASE_FILE, creditor.id);
          }
        },
        {
          param: this.queryParam.SET_UP_FEE,
          component: ChargeSetUpFeeDialog,
          title: () => 'creditors.triggerSetUpFee',
          permission: (creditor) => this.hasSetupFeePermission && creditor?.setUpFee !== 0,
          callback: (creditor) => {
            this.setQueryParam(this.queryParam.SET_UP_FEE, creditor.id);
          }
        },
        {
          param: this.queryParam.CREATE_USER,
          component: CreateUserDialog,
          title: () => 'users.createUser',
          permission: () => this.hasCreditorCreationPermission,
          condition: (creditor) => !creditor?.userId,
          callback: (creditor) => this.setQueryParam(this.queryParam.CREATE_USER, creditor.id)
        },
        {
          param: this.queryParam.UPDATE_USER,
          component: UpdateUserDialog,
          title: () => 'users.updateUser',
          permission: () => this.hasUpdatePermission,
          condition: (creditor) => !!creditor?.userId,
          callback: (creditor) => this.setQueryParam(this.queryParam.UPDATE_USER, creditor.id)
        },
        {
          param: this.queryParam.DISABLE_USER,
          component: DisableDialog,
          title: (creditor) =>
            `users.trigger${creditor.userActive === false ? 'Enable' : 'Disable'}User`,
          permission: (creditor) => this.hasDisableUserPermission && !!creditor?.userId,
          callback: (creditor) => {
            this.setQueryParam(this.queryParam.DISABLE_USER, creditor.id);
          }
        },
        {
          param: this.queryParam.RESET_PASSWORD,
          component: PasswordResetDialog,
          title: () => 'users.resetPassword',
          permission: (creditor) => this.hasPasswordResetPermission && !!creditor?.userId,
          callback: (creditor) => {
            this.setQueryParam(this.queryParam.RESET_PASSWORD, creditor.id);
          }
        },
        {
          param: this.queryParam.MANAGE_CONDITIONS,
          component: ManageConditionsDialog,
          title: () => 'creditors.manageConditions.title',
          permission: () => this.hasCreditorCreationPermission,
          callback: (creditor) => {
            this.setQueryParam(this.queryParam.MANAGE_CONDITIONS, creditor.id);
          }
        },
        {
          param: this.queryParam.REQUEST_SEPA_UPDATE,
          component: RequestSepaUpdateDialog,
          title: () => 'creditors.requestSepaUpdate.title',
          permission: (creditor) =>
            this.hasCreditorCreationPermission &&
            !creditor?.hasOpenSepaUpdateRequest &&
            creditor.isSentToSap,
          callback: (creditor) => {
            this.setQueryParam(this.queryParam.REQUEST_SEPA_UPDATE, creditor.id);
          }
        },
        {
          param: this.queryParam.SEND_REGISTRATION_MAIL,
          component: SendRegistrationMailDialog,
          title: () => 'creditors.sendRegistrationMail',
          permission: (creditor) => this.hasSendRegisterEmailPermission && !!creditor?.userId,
          callback: (creditor) => {
            this.setQueryParam(this.queryParam.SEND_REGISTRATION_MAIL, creditor.id);
          }
        },
        {
          param: this.queryParam.SET_CREDITOR_PERMISSIONS,
          component: SetCreditorPermissionsDialog,
          title: () => 'creditors.setCreditorPermissions.title',
          permission: () => this.hasUpdateCreditorPermissionsPermission,
          callback: (creditor) => {
            this.setQueryParam(this.queryParam.SET_CREDITOR_PERMISSIONS, creditor.id);
          }
        },
        {
          param: this.queryParam.SET_DRIVER_PERMISSIONS,
          component: SetDriverPermissionsDialog,
          title: () => 'creditors.setDriverPermissions.title',
          permission: (creditor) => this.hasEnableDoc2PortalPermission && !!creditor?.userId,
          callback: (creditor) => {
            this.setQueryParam(this.queryParam.SET_DRIVER_PERMISSIONS, creditor.id);
          }
        },
        {
          param: this.queryParam.TERMINATE_CREDITOR,
          component: TerminateCreditorDialog,
          title: (creditor) =>
            `creditors.terminate.trigger${
              creditor.isTerminated === false ? 'Terminate' : 'Reactivate'
            }Creditor`,
          permission: () => this.hasCreditorCreationPermission,
          callback: (creditor) => {
            this.setQueryParam(this.queryParam.TERMINATE_CREDITOR, creditor.id);
          }
        }
      ];
    },
    showActionDialog: {
      get() {
        return !!this.selectedAction.component;
      },
      set() {
        if (!this.selectedAction) {
          return;
        }
        this.setQueryParam(this.selectedAction.param, undefined);
      }
    },
    selectedAction() {
      return this.actions.find(
        (action) =>
          this.$route.query[action.param] &&
          (action.condition === undefined ||
            action.condition(this.getCreditorByQueryParam(action.param)) === true) &&
          (action.permission === undefined ||
            action.permission(this.getCreditorByQueryParam(action.param)) === true)
      );
    },
    creditorNotFound() {
      return (
        !this.detailedCreditor &&
        !!this.selectedAction &&
        this.getQueryParam(this.selectedAction.param) !== true
      );
    }
  },

  watch: {
    '$route.query': {
      deep: true,
      handler(query) {
        this.updateTableQueries(query, this.queryValues, this.loadCreditors);
      }
    },
    selectedAction() {
      if (this.creditorNotFound) {
        this.showActionDialog = false;
      }
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchCreditors']),

    loadCreditors(query = this.defaultQuery) {
      this.fetchCreditors({
        page: query[QueryParam.PAGE],
        rowsPerPage: query[QueryParam.ITEMS_PER_PAGE],
        filters: this.buildFilters(),
        sorting: this.buildSorting(query)
      });
    },

    buildFilters() {
      let filters = {};

      if (this.statusSelection.length > 0) {
        filters.status = this.statusSelection;
      }

      if (this.creditorNameSelection.length > 0) {
        filters.name = this.creditorNameSelection;
      }

      if (this.userNameSelection.length > 0) {
        filters.username = this.userNameSelection;
      }

      if (this.sapNumberSelection.length > 0) {
        filters.sapNumber = this.sapNumberSelection;
      }

      if (this.citySelection.length > 0) {
        filters.city = this.citySelection;
      }

      if (this.productSelection.length > 0) {
        filters.product = this.productSelection;
      }

      if (this.userCreatedSelection.length > 0) {
        filters.userExists = this.userCreatedSelection[0] === 'yes';
      }

      if (this.sapRegisteredSelection.length > 0) {
        filters.sapSend = this.sapRegisteredSelection[0] === 'yes';
      }

      return Object.keys(filters).length > 0 ? filters : undefined;
    },

    getCreditorByQueryParam(param) {
      return this.getCreditorById(this.$route.query[param]);
    }
  },

  created() {
    this.loadCreditors();
  }
};
</script>
