import { mapGetters } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  mixins: [ShowDialogMixin],

  props: {
    creditorId: {
      type: String,
      required: false,
      default: null
    }
  },

  data: () => ({
    details: {}
  }),

  watch: {
    creditorId: {
      immediate: true,
      handler(creditor) {
        if (!creditor) {
          this.details = {};
          return;
        }

        this.details = this.getById(creditor);
      }
    }
  },

  computed: {
    ...mapGetters(NAMESPACE, { getById: 'GET_CREDITOR_BY_ID' })
  },

  methods: {
    close() {
      this.error = null;
      this.$emit('input', false);
    }
  }
};
