<template>
  <v-row dense>
    <v-col cols="4" md="3" lg="2">
      <creditor-status-select v-model="statusSelection" />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <creditor-name-select v-model="creditorNameSelection" />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <user-name-select v-model="userNameSelection" />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <sap-number-select v-model="sapNumberSelection" />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <city-select v-model="citySelection" />
    </v-col>
    <v-col v-if="!isProductAdmin" cols="4" md="3" lg="2">
      <product-select v-model="productSelection" />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <registration-status-filter
        :sap-registered.sync="sapRegisteredSelection"
        :set-up-fee-charged.sync="setUpFeeChargedSelection"
        :user-created.sync="userCreatedSelection"
      />
    </v-col>

    <v-col cols="12" class="pt-3 pb-4">
      <active-filter-chip
        v-for="(selection, index) in statusSelection"
        :key="selection"
        v-model="statusSelection"
        :index="index"
      >
        {{ selection | formatCreditorStatus }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in creditorNameSelection"
        :key="selection"
        v-model="creditorNameSelection"
        :index="index"
      >
        {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in userNameSelection"
        :key="selection"
        v-model="userNameSelection"
        :index="index"
      >
        {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in sapNumberSelection"
        :key="selection"
        v-model="sapNumberSelection"
        :index="index"
      >
        {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in citySelection"
        :key="selection"
        v-model="citySelection"
        :index="index"
      >
        {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in productSelection"
        :key="selection"
        v-model="productSelection"
        :index="index"
      >
        {{ selection | formatProduct }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in sapRegisteredSelection"
        :key="'sapRegisteredSelection' + selection"
        v-model="sapRegisteredSelection"
        :index="index"
      >
        {{ $t('creditors.registrationStates.sapRegistered') }}: {{ $t(selection) }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in setUpFeeChargedSelection"
        :key="'setUpFeeChargedSelection' + selection"
        v-model="setUpFeeChargedSelection"
        :index="index"
      >
        {{ $t('creditors.registrationStates.setUpFeeCharged') }}: {{ $t(selection) }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in userCreatedSelection"
        :key="'userCreatedSelection' + selection"
        v-model="userCreatedSelection"
        :index="index"
      >
        {{ $t('creditors.registrationStates.userCreated') }}: {{ $t(selection) }}
      </active-filter-chip>

      <app-text-btn
        v-if="hasActiveFilters"
        color="background-text"
        class="caption"
        title="resetFilter"
        @click="resetAllFilter"
      />
    </v-col>
  </v-row>
</template>

<script>
import ActiveFilterChip from '@/shared/components/ActiveFilterChip';
import CitySelect from '@/modules/Admin/components/Filter/CitySelect';
import CreditorListMixin from '@/modules/Admin/mixins/CreditorListMixin';
import CreditorNameSelect from '@/modules/Admin/components/Filter/CreditorNameSelect';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import ProductSelect from '@/modules/Admin/components/Filter/ProductSelect';
import RegistrationStatusFilter from '@/modules/Admin/components/Filter/RegistrationStatusFilter';
import SapNumberSelect from '@/modules/Admin/components/Filter/SapNumberSelect';
import CreditorStatusSelect from '@/modules/Admin/components/Filter/CreditorStatusSelect';
import UserNameSelect from '@/modules/Admin/components/Filter/UserNameSelect';

export default {
  name: 'CreditorsFilter',

  mixins: [ListFilterMixin, CreditorListMixin],

  components: {
    ActiveFilterChip,
    CitySelect,
    CreditorNameSelect,
    ProductSelect,
    RegistrationStatusFilter,
    SapNumberSelect,
    CreditorStatusSelect,
    UserNameSelect
  }
};
</script>
