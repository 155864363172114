<template>
  <div>
    <app-dialog
      v-model="open"
      :fill-actions="false"
      :loading="loading"
      v-bind="dialogBindings.props"
      width="1100px"
      @close="close"
      v-on="dialogBindings.listeners"
    >
      <app-spinner v-if="loadingConditions" />
      <template v-else>
        <ValidationObserver ref="form" tag="form">
          <component
            :is="dialogBindings.component"
            ref="action"
            :conditions="conditions"
            :details="details"
            :start-create="startAdd"
            :service-fee-matrix-id.sync="newConditions.serviceFeeMatrixId"
            :start-date.sync="newConditions.startDate"
            :start-delete-id.sync="startDeleteId"
            @start-delete="startDeleteId = $event"
          />
        </ValidationObserver>
      </template>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="creditors.manageConditions.confirmChange"
      @submit="onCloseDeleteConfirmation"
      @close="onCloseDeleteConfirmation"
    />
  </div>
</template>

<script>
import AddConditions from '@/modules/Admin/components/Creditors/Actions/ManageConditionsDialog/AddConditions';
import CreditorDialogMixin from '@/mixins/CreditorDialogMixin';
import DeleteConditions from '@/modules/Admin/components/Creditors/Actions/ManageConditionsDialog/DeleteConditions';
import ViewConditions from '@/modules/Admin/components/Creditors/Actions/ManageConditionsDialog/ViewConditions';
import { NAMESPACE } from '@/modules/Admin/store';
import { formatDateToTimestamp } from '@/helper/filter/formatDate';
import { mapActions } from 'vuex';

export default {
  name: 'ManageConditionsDialog',

  mixins: [CreditorDialogMixin],

  data: () => ({
    conditions: [],
    loadingConditions: false,
    startAdd: false,
    startDeleteId: '',
    newConditions: {
      serviceFeeMatrixId: '',
      startDate: ''
    }
  }),

  computed: {
    dialogBindings() {
      if (this.startDeleteId) {
        return {
          component: DeleteConditions,
          props: {
            title: 'creditors.manageConditions.deleteTitle',
            primaryActionText: 'navigation.yesDelete',
            secondaryActionText: 'navigation.noCancel',
            useCancelAction: true
          },
          listeners: {
            submit: this.onDeleteConditions,
            cancel: this.reset
          }
        };
      }

      if (this.startAdd) {
        return {
          component: AddConditions,
          props: {
            title: 'creditors.manageConditions.addNewConditions',
            primaryActionText: 'navigation.send',
            secondaryActionText: 'navigation.cancel',
            useCancelAction: true
          },
          listeners: {
            submit: this.onAddConditions,
            cancel: this.reset
          }
        };
      }

      return {
        component: ViewConditions,
        props: {
          title: 'creditors.manageConditions.title',
          primaryActionText: 'creditors.manageConditions.addNew'
        },
        listeners: {
          submit: () => (this.startAdd = true),
          'start-delete': (id) => (this.startDeleteId = id)
        }
      };
    }
  },

  watch: {
    creditorId: {
      handler() {
        this.fetchConditions();
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, [
      'fetchCreditorServiceFeeConditions',
      'deleteCreditorServiceFeeConditions',
      'addCreditorServiceFeeConditions'
    ]),

    async fetchConditions() {
      this.loadingConditions = true;
      const { data, error } = await this.fetchCreditorServiceFeeConditions(this.creditorId);
      this.loadingConditions = false;

      if (error) {
        return;
      }

      this.conditions = data;
    },

    async onAddConditions() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;
      const { error } = await this.addCreditorServiceFeeConditions({
        creditor: {
          identifier: this.details.id
        },
        condition: {
          sapMatrixIdentifier: this.newConditions.serviceFeeMatrixId,
          validFromDate: formatDateToTimestamp(this.newConditions.startDate)
        }
      });
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
    },

    async onDeleteConditions() {
      this.loading = true;
      const { error } = await this.deleteCreditorServiceFeeConditions(
        this.conditions.find((condition) => condition.id === this.startDeleteId).id
      );
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
    },

    onSuccess() {
      this.submitted = true;
    },

    onCloseDeleteConfirmation() {
      this.fetchConditions();
      setTimeout(() => this.reset(), 200);
    },

    reset() {
      this.$refs.form?.reset();
      this.startAdd = false;
      this.startDeleteId = '';
      this.newConditions = {
        serviceFeeMatrixId: '',
        startDate: ''
      };
      this.submitted = false;
    }
  }
};
</script>
