<template>
  <div>
    <app-dialog
      v-model="open"
      title="creditors.setDriverPermissions.title"
      primary-action-text="navigation.confirm"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      :disabled="!changedPermissionDoc2Portal && !changedPermissionLetter2Post"
      @submit="onSubmit"
      @close="close"
    >
      <p>
        {{ $t('creditors.setDriverPermissions.requestChange', { name: details.creditorName }) }}
      </p>
      <app-input-row class="mt-4">
        <app-checkbox v-model="enableDoc2Portal">
          <div>
            {{ $t('masterData.doc2Portal') }}
            <div class="caption grey--text">
              {{ $t('creditors.setDriverPermissions.uploadDoc2Portal') }}
            </div>
          </div>
        </app-checkbox>
      </app-input-row>
      <app-input-row>
        <app-checkbox v-model="enableLetter2Post">
          <div>
            {{ $t('masterData.letter2Post') }}
            <div class="caption grey--text">
              {{ $t('creditors.setDriverPermissions.sendLetter2Post') }}
            </div>
          </div>
        </app-checkbox>
      </app-input-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="creditors.setDriverPermissions.confirmChange"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';
import CreditorDialogMixin from '@/mixins/CreditorDialogMixin';

export default {
  name: 'SetDriverPermissionsDialog',

  mixins: [CreditorDialogMixin],

  data: () => ({
    enableDoc2Portal: false,
    enableLetter2Post: false
  }),

  computed: {
    changedPermissionDoc2Portal() {
      return this.enableDoc2Portal !== this.details.doc2PortalEnabled;
    },
    changedPermissionLetter2Post() {
      return this.enableLetter2Post !== this.details.letter2PostEnabled;
    }
  },

  watch: {
    creditorId: {
      handler() {
        this.enableDoc2Portal = this.details.doc2PortalEnabled ?? false;
        this.enableLetter2Post = this.details.letter2PostEnabled ?? false;
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, [
      'enableDriverDoc2Portal',
      'disableDriverDoc2Portal',
      'enableDriverLetter2Post',
      'disableDriverLetter2Post'
    ]),

    async onSubmit() {
      this.loading = true;

      if (this.changedPermissionDoc2Portal) {
        const { error } = await (this.enableDoc2Portal
          ? this.enableDriverDoc2Portal(this.creditorId)
          : this.disableDriverDoc2Portal(this.creditorId));

        if (error) {
          this.loading = false;
          return;
        }
      }

      if (this.changedPermissionLetter2Post) {
        const { error } = await (this.enableLetter2Post
          ? this.enableDriverLetter2Post(this.creditorId)
          : this.disableDriverLetter2Post(this.creditorId));

        if (error) {
          this.loading = false;
          return;
        }
      }

      this.loading = false;
      this.submitted = true;
    }
  }
};
</script>
