<template>
  <v-timeline dense align-top class="mx-4">
    <template v-for="(condition, index) in conditions">
      <v-timeline-item
        :key="condition.id"
        :color="condition.isCurrent ? 'text' : 'white'"
        :class="{
          'condition-list__timeline-item': true,
          'timeline-item--lined': index + 1 < conditions.length
        }"
      >
        <div class="condition__wrapper">
          <div :class="{ condition__header: true, 'semi-bold': condition.isCurrent }">
            <div class="condition__date">
              {{ getDate(condition) }}
            </div>
            <div class="d-flex mt-1 align-center">
              {{ condition.serviceFeeMatrix.name }}
              <app-icon-btn
                v-if="checkIsInFuture(condition)"
                class="ml-2 my-n2"
                color="button-link"
                icon="icon-trash-can"
                size="18"
                @click="$emit('start-delete', condition.id)"
              />
            </div>
          </div>

          <service-fee-condition-details
            :service-fee-matrix="condition.serviceFeeMatrix"
            :creation-date="condition.created"
            :start-date="condition.startDate"
            :end-date="condition.endDate"
            :is-current="condition.isCurrent"
          />
        </div>
      </v-timeline-item>
    </template>
  </v-timeline>
</template>

<script>
import formatDate from '@/helper/filter/formatDate';
import ServiceFeeConditionDetails from '@/modules/Admin/components/Creditors/ServiceFeeConditionDetails';
import ServiceFeeConditionStatus from '@/modules/Admin/statics/serviceFeeConditionStatus';

export default {
  name: 'ViewConditions',

  components: { ServiceFeeConditionDetails },

  props: {
    conditions: {
      type: Array,
      required: true
    }
  },

  methods: {
    checkIsInFuture(condition) {
      return condition.status === ServiceFeeConditionStatus.IN_FUTURE;
    },
    getDate(condition) {
      if (condition.isCurrent) {
        return `${this.$t('since')} ${formatDate(condition.startDate)}`;
      }
      if (condition.startDate > Date.now()) {
        return `${this.$t('from')} ${formatDate(condition.startDate)}`;
      }

      return `${formatDate(condition.startDate)} - ${formatDate(condition.endDate)}`;
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/shared/styles/timeline';

$padding-bottom: 48px;

.condition-list__timeline-item {
  padding-bottom: calc(#{$padding-bottom} / 2);

  &:not(:last-of-type) .condition__wrapper {
    border-bottom: 1px solid var(--c-border);
  }
}

.condition__wrapper {
  margin-inline: 16px;
  display: flex;
  gap: 12px 32px;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: calc(#{$padding-bottom} / 2);
}

.condition__header {
  min-width: 300px;
}

.condition__date {
  font-size: 0.75rem;
  color: var(--c-grey);
}
</style>
