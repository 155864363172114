<template>
  <div>
    {{ $t('creditors.manageConditions.deleteInfo') }}
    <div class="px-6 pt-8">
      <span class="d-inline-block pb-2 semi-bold">
        {{ conditionToDelete.serviceFeeMatrix.name }}
      </span>

      <service-fee-condition-details
        :service-fee-matrix="conditionToDelete.serviceFeeMatrix"
        :creation-date="conditionToDelete.created"
        :start-date="conditionToDelete.startDate"
        :end-date="conditionToDelete.endDate"
        :is-current="conditionToDelete.isCurrent"
      />
    </div>
  </div>
</template>
<script>
import ServiceFeeConditionDetails from '@/modules/Admin/components/Creditors/ServiceFeeConditionDetails';

export default {
  name: 'DeleteConditions',

  components: { ServiceFeeConditionDetails },

  props: {
    conditions: {
      type: Array,
      required: true
    },
    startDeleteId: {
      type: String,
      required: true
    }
  },

  computed: {
    conditionToDelete() {
      return this.conditions.find((condition) => condition.id === this.startDeleteId);
    }
  }
};
</script>
