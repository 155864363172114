<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form" @submit.prevent="onSubmit">
    <app-dialog
      v-model="open"
      title="users.createUser"
      primary-action-text="navigation.send"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      :disabled="failed"
      @submit="onSubmit"
      @close="close"
    >
      <app-alert :value="emailAlreadyAssigned" class="mb-4">
        {{ $t('users.userEmailAlreadyAssigned') }}
      </app-alert>
      <v-row>
        <v-col cols="12">
          <app-text-field v-model="formData.name" label="name" required />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <app-text-field v-model="formData.email" label="email" rules="email|required" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <app-text-field
            id="new-password"
            v-model="formData.password"
            autocomplete="new-password"
            label="password"
            required
            rules="min:8|password_constraints"
            type="password"
          />
          <password v-model="formData.password" :strength-meter-only="true" :secure-length="8" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <app-checkbox v-model="formData.sendMail" label="users.sendRegistrationMail" />
        </v-col>
      </v-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="users.createdUser"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
import Password from 'vue-password-strength-meter';
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';
import { ERROR_CODE_USER_EMAIL_ALREADY_ASSIGNED } from '@/statics/errorCodes';
import showSnackbar from '@/helper/showSnackbar';
import CreditorDialogMixin from '@/mixins/CreditorDialogMixin';

export default {
  name: 'CreateUserDialog',

  mixins: [CreditorDialogMixin],

  components: {
    Password
  },

  data: () => ({
    formData: {
      name: '',
      email: '',
      password: '',
      sendMail: false
    },
    emailAlreadyAssigned: false
  }),

  methods: {
    ...mapActions(NAMESPACE, ['createUser']),

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;
      const payload = {
        creditorIdentifier: this.creditorId,
        name: this.formData.name,
        email: this.formData.email,
        password: this.formData.password,
        sendMail: this.formData.sendMail
      };

      const { error } = await this.createUser(payload);

      this.loading = false;
      if (error) {
        if (error.response?.data.error === ERROR_CODE_USER_EMAIL_ALREADY_ASSIGNED) {
          this.emailAlreadyAssigned = true;
          return;
        }

        showSnackbar({ text: 'serverError', color: 'error' });
        return;
      }

      this.submitted = true;
      setTimeout(() => this.resetForm(), 200);
    },

    resetForm() {
      this.$refs.form?.reset();
      this.emailAlreadyAssigned = false;
      this.formData = {
        name: '',
        email: '',
        password: '',
        sendMail: false
      };
    }
  },

  watch: {
    details: {
      handler(details) {
        if (!details.email) return;
        this.resetForm();

        this.formData.email = details.email;
        this.formData.name = details.creditorName;
      },
      immediate: true
    }
  }
};
</script>
