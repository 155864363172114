<template>
  <div>
    <app-dialog
      v-model="open"
      title="creditors.sendRegistrationMail"
      primary-action-text="navigation.yesSend"
      secondary-action-text="navigation.noCancel"
      :loading="loading"
      @submit="onSubmit"
      @close="close"
    >
      <p>{{ $t('creditors.requestRegistrationMail', { name: details.creditorName }) }}</p>

      <p class="mt-4 semi-bold">
        {{ $t('creditors.registrationMailWarning') }}
      </p>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="creditors.sentRegistrationMail"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Admin/store';
import CreditorDialogMixin from '@/mixins/CreditorDialogMixin';

export default {
  name: 'SendRegistrationMailDialog',

  mixins: [CreditorDialogMixin],

  methods: {
    ...mapActions(NAMESPACE, ['sendRegistrationMail']),

    async onSubmit() {
      this.loading = true;

      const { error } = await this.sendRegistrationMail(this.creditorId);

      this.loading = false;
      if (error) {
        this.error = error.response?.data;
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
