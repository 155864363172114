<template>
  <div>
    <app-dialog
      v-model="open"
      title="creditors.setCreditorPermissions.title"
      primary-action-text="navigation.confirm"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      @submit="onSubmit"
      @close="close"
    >
      <p class="mb-4">
        {{ $t('creditors.setCreditorPermissions.requestChange', { name: details.creditorName }) }}
      </p>
      <app-input-row v-for="permission in availablePermissions" :key="permission.dataName">
        <app-checkbox v-model="updatedPermissions[permission.dataName]">
          <div>
            {{ permission.title }}
            <div class="caption grey--text">
              {{ permission.text }}
            </div>
          </div>
        </app-checkbox>
      </app-input-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="creditors.setCreditorPermissions.confirmChange"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import CreditorDialogMixin from '@/mixins/CreditorDialogMixin';
import CreditorPermission from '@/modules/User/statics/creditorPermission';
import { BILLING_PRODUCTS, FACTORING_PRODUCTS } from '@/statics/productType';
import { NAMESPACE } from '@/modules/Admin/store';
import { mapActions } from 'vuex';

export default {
  name: 'SetCreditorPermissionsDialog',

  mixins: [CreditorDialogMixin],

  data: () => ({
    updatedPermissions: {}
  }),

  computed: {
    creditorPermissions() {
      return [
        {
          title: this.$t('creditors.setCreditorPermissions.directPayment'),
          text: this.$t('creditors.setCreditorPermissions.directPaymentInfo'),
          currentValue: this.details.permissions.directPayment,
          dataName: CreditorPermission.DIRECT_PAYMENT
        },
        {
          title: this.$t('creditors.setCreditorPermissions.determineSolvency'),
          text: this.$t('creditors.setCreditorPermissions.determineSolvencyInfo'),
          currentValue: this.details.permissions.checkSolvency,
          dataName: CreditorPermission.CHECK_SOLVENCY,
          condition: BILLING_PRODUCTS.includes(this.details.product)
        },
        {
          title: this.$t('creditors.setCreditorPermissions.vrsManualCheckMandatory'),
          text: this.$t('creditors.setCreditorPermissions.vrsManualCheckMandatoryInfo'),
          currentValue: this.details.permissions.vrsManualCheckMandatory,
          dataName: CreditorPermission.VRS_MANUAL_CHECK_MANDATORY,
          condition: FACTORING_PRODUCTS.includes(this.details.product)
        }
      ];
    },
    availablePermissions() {
      return this.filterItemsWithFulfilledCondition(this.creditorPermissions);
    }
  },

  watch: {
    creditorId: {
      handler() {
        this.initPermissionsFromCreditor();
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['updateCreditorPermissions']),

    initPermissionsFromCreditor() {
      this.creditorPermissions.forEach((permission) => {
        this.updatedPermissions[permission.dataName] = permission.currentValue;
      });
    },

    async onSubmit() {
      if (
        this.creditorPermissions.every(
          (permission) => permission.currentValue === this.updatedPermissions[permission.dataName]
        )
      ) {
        this.submitted = true;
        return;
      }

      this.loading = true;

      const { error } = await this.updateCreditorPermissions({
        identifier: this.creditorId,
        permissions: this.updatedPermissions
      });

      if (error) {
        this.loading = false;
        return;
      }

      this.loading = false;
      this.submitted = true;
    }
  }
};
</script>
